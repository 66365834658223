.card-bordered {
  border: 8px solid var(--color);
  border-radius: 2px;
}

.basket-item {
  display: flex;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #f4f4f4;

  .product-info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .product-name {
      color: var(--font-600);
      font-size: 14px;
      line-height: 18px;
    }

    .product-price {
      margin-top: 5px;
      color: var(--color);
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
    }
  }

  .product-counter {
    display: flex;

    .product-count-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      background-color: var(--color);
      color: var(--white);
    }
  }
}

.basket-total {
  margin-left: auto;
  padding: 17px 24px;
  border: 2px solid var(--color);
  border-radius: 2px;
  color: var(--color);
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
}
