@import "~@logo-software/theme/base";

.product-list {
  h1 {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    color: var(--font-500);
    font-size: 20px;
    font-weight: normal;
    letter-spacing: 0.25px;
    line-height: 26px;
  }

  .product-filter-area {
    display: flex;
    align-items: center;
    margin: 0 0 16px;

    .item-types {
      flex-grow: 1;
      padding: 0;
      list-style: none;

      .tag {
        display: inline-flex;
        margin-right: 8px;
        padding: 6px 16px;
        border-radius: 2px;
        background-color: #f2f0fd;
        color: var(--color);
        font-size: 13px;
        font-weight: 600;
        line-height: 18px;
        cursor: pointer;

        &:hover {
          background-color: darken(#f2f0fd, 1);
        }

        &.selected {
          background-color: #1ea4ce;
          color: #f2f0fd;
        }
      }
    }

    .found-count {
      color: var(--font-500);
      font-size: 10px;
      text-align: right;
    }
  }

  .product-area {
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0 4px 24px rgba(93, 62, 188, 0.04);
    background-color: var(--white);
    color: var(--font-600);
    font-size: 14px;

    &.grid {
      display: grid;
      gap: var(--grid-gap);
      grid-template-columns: repeat(4, 1fr);
    }

    .product-item {
      overflow-wrap: anywhere;
      word-break: break-word;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;

      .product-image {
        padding: 16px;
        height: 92px;
        border: 1px solid #f3f0fe;
        border-radius: 12px;
        background-clip: content-box;
        background-color: #c4c4c4;
      }

      .product-price {
        margin-top: 8px;
        color: var(--color);
      }

      .product-name {
        display: flex;
        flex-grow: 1;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      }

      .product-add {
        margin-top: 8px;
        height: 22px;
        background-color: var(--color);
        color: var(--white);
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
      }
    }

    @include smaller-than(lg) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
