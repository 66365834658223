.basket-summary {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  padding: 0 24px;
  height: 100%;
  background-color: var(--color-dark);
  color: var(--white);

  .icon {
    width: 24px;
    height: 26px;
    background-image: url("../../../assets/basket.svg");
    background-repeat: no-repeat;
  }

  .price {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }
}
