:root {
  .filter {
    width: 100%;
    height: 48px;
    border: 2px solid #e0e0e0;
    background-color: var(--white);

    &:not([type]):focus {
      border: 2px solid var(--primary) !important;
    }
  }

  ul.filter-list {
    margin: 17px 0 0 0;
    padding: 0 0 0 10px;
    max-height: 274px;
    overflow: auto;
    list-style: none;

    li {
      display: flex;
    }
  }
}
