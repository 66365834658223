ul.sorting-list {
  margin: 17px 0 0 0;
  padding: 0 0 0 10px;
  overflow: auto;
  list-style: none;

  li {
    display: flex;
  }
}
