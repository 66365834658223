.card {
  margin-bottom: 24px;

  .label {
    margin-bottom: 12px;
    color: var(--font-500);
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 24px;
    border-radius: 2px;
    box-shadow: 0 6px 24px rgba(93, 62, 188, 0.04);
    background-color: var(--white);
    color: var(--font-600);
    font-size: 14px;
    line-height: 18px;
  }
}
