header {
  margin-bottom: 40px;
  background-color: var(--color);

  .container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 130px;
      height: 76.64px;
      text-align: center;
    }
  }
}
